define("discourse/plugins/chat/discourse/routes/chat-channel-thread", ["exports", "@ember/object", "@ember/service", "discourse/routes/discourse"], function (_exports, _object, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelThread extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    redirectToChannel(channel, transition) {
      transition.abort();
      this.chatStateManager.closeSidePanel();
      this.router.transitionTo("chat.channel", ...channel.routeModels);
    }
    model(params, transition) {
      const channel = this.modelFor("chat.channel");
      return channel.threadsManager.find(channel.id, params.threadId).catch(() => {
        this.redirectToChannel(channel, transition);
        return;
      });
    }
    afterModel(thread, transition) {
      const channel = this.modelFor("chat.channel");
      if (!channel.threadingEnabled && !thread.force) {
        this.redirectToChannel(channel, transition);
        return;
      }
      channel.activeThread = thread;
    }
    activate() {
      this.chat.activeMessage = null;
      this.chatStateManager.openSidePanel();
    }
    static #_4 = (() => dt7948.n(this.prototype, "activate", [_object.action]))();
    deactivate() {
      this.chatStateManager.closeSidePanel();
    }
    static #_5 = (() => dt7948.n(this.prototype, "deactivate", [_object.action]))();
    beforeModel() {
      const {
        messageId
      } = this.paramsFor(this.routeName + ".near-message");
      if (!messageId && this.controllerFor("chat-channel-thread").get("targetMessageId")) {
        this.controllerFor("chat-channel-thread").set("targetMessageId", null);
      }
    }
  }
  _exports.default = ChatChannelThread;
});