define("discourse/plugins/chat/discourse/components/chat-drawer", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/service", "@ember/template", "@ember-decorators/component", "@ember-decorators/object", "discourse/lib/decorators", "discourse/lib/get-url", "discourse/lib/url", "discourse/lib/utilities", "@ember/template-factory"], function (_exports, _component, _object, _runloop, _service, _template, _component2, _object2, _decorators, _getUrl, _url, _utilities, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.chatStateManager.isDrawerActive}}
    {{bodyClass "chat-drawer-active"}}
  {{/if}}
  
  {{#if this.chatStateManager.isDrawerExpanded}}
    {{bodyClass "chat-drawer-expanded"}}
  {{/if}}
  
  {{#if this.chatStateManager.isDrawerActive}}
    <div
      data-chat-channel-id={{this.chatDrawerRouter.model.channel.id}}
      data-chat-thread-id={{this.chatDrawerRouter.model.channel.activeThread.id}}
      class={{concat-class
        "chat-drawer"
        (if this.chatStateManager.isDrawerExpanded "is-expanded" "is-collapsed")
      }}
      {{chat/resizable-node ".chat-drawer-resizer" this.didResize}}
      style={{this.drawerStyle}}
    >
      <div class="chat-drawer-container">
        <div class="chat-drawer-resizer"></div>
  
        <PluginOutlet
          @name="chat-drawer-before-content"
          @outletArgs={{hash
            currentRouteName=this.chatDrawerRouter.currentRouteName
          }}
        />
  
        <this.chatDrawerRouter.component
          @params={{this.chatDrawerRouter.params}}
          @model={{this.chatDrawerRouter.model}}
          @openURL={{this.openURL}}
          @openInFullPage={{this.openInFullPage}}
          @toggleExpand={{this.toggleExpand}}
          @close={{this.close}}
          @drawerActions={{this.drawerActions}}
        />
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "lwK7WdvL",
    "block": "[[[41,[30,0,[\"chatStateManager\",\"isDrawerActive\"]],[[[1,\"  \"],[1,[28,[35,1],[\"chat-drawer-active\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"  \"],[1,[28,[35,1],[\"chat-drawer-expanded\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerActive\"]],[[[1,\"  \"],[11,0],[16,\"data-chat-channel-id\",[30,0,[\"chatDrawerRouter\",\"model\",\"channel\",\"id\"]]],[16,\"data-chat-thread-id\",[30,0,[\"chatDrawerRouter\",\"model\",\"channel\",\"activeThread\",\"id\"]]],[16,0,[28,[37,3],[\"chat-drawer\",[52,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],\"is-expanded\",\"is-collapsed\"]],null]],[16,5,[30,0,[\"drawerStyle\"]]],[4,[38,4],[\".chat-drawer-resizer\",[30,0,[\"didResize\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"chat-drawer-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-drawer-resizer\"],[12],[13],[1,\"\\n\\n      \"],[8,[39,5],null,[[\"@name\",\"@outletArgs\"],[\"chat-drawer-before-content\",[28,[37,6],null,[[\"currentRouteName\"],[[30,0,[\"chatDrawerRouter\",\"currentRouteName\"]]]]]]],null],[1,\"\\n\\n      \"],[8,[30,0,[\"chatDrawerRouter\",\"component\"]],null,[[\"@params\",\"@model\",\"@openURL\",\"@openInFullPage\",\"@toggleExpand\",\"@close\",\"@drawerActions\"],[[30,0,[\"chatDrawerRouter\",\"params\"]],[30,0,[\"chatDrawerRouter\",\"model\"]],[30,0,[\"openURL\"]],[30,0,[\"openInFullPage\"]],[30,0,[\"toggleExpand\"]],[30,0,[\"close\"]],[30,0,[\"drawerActions\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"bodyClass\",\"div\",\"concat-class\",\"chat/resizable-node\",\"plugin-outlet\",\"hash\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-drawer.hbs",
    "isStrictMode": false
  });
  const ChatDrawer = dt7948.c(class ChatDrawer extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatDrawerSize", [_service.service]))();
    #chatDrawerSize = (() => (dt7948.i(this, "chatDrawerSize"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "chatDrawerRouter", [_service.service]))();
    #chatDrawerRouter = (() => (dt7948.i(this, "chatDrawerRouter"), void 0))();
    loading = false;
    sizeTimer = null;
    rafTimer = null;
    hasUnreadMessages = false;
    drawerStyle = null;
    didInsertElement() {
      super.didInsertElement(...arguments);
      if (!this.chat.userCanChat) {
        return;
      }
      this._checkSize();
      this.appEvents.on("chat:open-url", this, "openURL");
      this.appEvents.on("chat:toggle-close", this, "close");
      this.appEvents.on("composer:closed", this, "_checkSize");
      this.appEvents.on("composer:opened", this, "_checkSize");
      this.appEvents.on("composer:resized", this, "_checkSize");
      this.appEvents.on("composer:div-resizing", this, "_dynamicCheckSize");
      window.addEventListener("resize", this._checkSize);
      this.appEvents.on("composer:resize-started", this, "_startDynamicCheckSize");
      this.appEvents.on("composer:resize-ended", this, "_clearDynamicCheckSize");
      this.computeDrawerStyle();
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      if (!this.chat.userCanChat) {
        return;
      }
      window.removeEventListener("resize", this._checkSize);
      if (this.appEvents) {
        this.appEvents.off("chat:open-url", this, "openURL");
        this.appEvents.off("chat:toggle-close", this, "close");
        this.appEvents.off("composer:closed", this, "_checkSize");
        this.appEvents.off("composer:opened", this, "_checkSize");
        this.appEvents.off("composer:resized", this, "_checkSize");
        this.appEvents.off("composer:div-resizing", this, "_dynamicCheckSize");
        this.appEvents.off("composer:resize-started", this, "_startDynamicCheckSize");
        this.appEvents.off("composer:resize-ended", this, "_clearDynamicCheckSize");
      }
      if (this.sizeTimer) {
        (0, _runloop.cancel)(this.sizeTimer);
        this.sizeTimer = null;
      }
      if (this.rafTimer) {
        window.cancelAnimationFrame(this.rafTimer);
      }
    }
    _fireHiddenAppEvents() {
      this.appEvents.trigger("chat:rerender-header");
    }
    static #_7 = (() => dt7948.n(this.prototype, "_fireHiddenAppEvents", [(0, _object2.observes)("chatStateManager.isDrawerActive")]))();
    computeDrawerStyle() {
      const {
        width,
        height
      } = this.chatDrawerSize.size;
      let style = `width: ${(0, _utilities.escapeExpression)((width || "0").toString())}px;`;
      style += `height: ${(0, _utilities.escapeExpression)((height || "0").toString())}px;`;
      this.set("drawerStyle", (0, _template.htmlSafe)(style));
    }
    get drawerActions() {
      return {
        openInFullPage: this.openInFullPage,
        close: this.close,
        toggleExpand: this.toggleExpand
      };
    }
    _dynamicCheckSize() {
      if (!this.chatStateManager.isDrawerActive) {
        return;
      }
      if (this.rafTimer) {
        return;
      }
      this.rafTimer = window.requestAnimationFrame(() => {
        this.rafTimer = null;
        this._performCheckSize();
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "_dynamicCheckSize", [_decorators.bind]))();
    _startDynamicCheckSize() {
      if (!this.chatStateManager.isDrawerActive) {
        return;
      }
      document.querySelector(".chat-drawer-outlet-container").classList.add("clear-transitions");
    }
    _clearDynamicCheckSize() {
      if (!this.chatStateManager.isDrawerActive) {
        return;
      }
      document.querySelector(".chat-drawer-outlet-container").classList.remove("clear-transitions");
      this._checkSize();
    }
    _checkSize() {
      this.sizeTimer = (0, _runloop.throttle)(this, this._performCheckSize, 150);
    }
    static #_9 = (() => dt7948.n(this.prototype, "_checkSize", [_decorators.bind]))();
    _performCheckSize() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      const drawerContainer = document.querySelector(".chat-drawer-outlet-container");
      if (!drawerContainer) {
        return;
      }
      const composer = document.getElementById("reply-control");
      const composerIsClosed = composer.classList.contains("closed");
      const minRightMargin = 15;
      drawerContainer.style.setProperty("--composer-right", (composerIsClosed ? minRightMargin : Math.max(minRightMargin, composer.offsetLeft)) + "px");
    }
    openURL() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.chat.activeChannel = null;
      this.chatDrawerRouter.stateFor(this._routeFromURL(url));
      this.chatStateManager.didOpenDrawer(url);
    }
    static #_10 = (() => dt7948.n(this.prototype, "openURL", [_object.action]))();
    _routeFromURL(url) {
      let route = this.router.recognize((0, _getUrl.default)(url || "/"));

      // ember might recognize the index subroute
      if (route.localName === "index") {
        route = route.parent;
      }
      return route;
    }
    async openInFullPage() {
      this.chatStateManager.storeAppURL();
      this.chatStateManager.prefersFullPage();
      this.chat.activeChannel = null;
      await new Promise(resolve => (0, _runloop.next)(resolve));
      return _url.default.routeTo(this.chatStateManager.lastKnownChatURL);
    }
    static #_11 = (() => dt7948.n(this.prototype, "openInFullPage", [_object.action]))();
    toggleExpand() {
      this.computeDrawerStyle();
      this.chatStateManager.didToggleDrawer();
      this.appEvents.trigger("chat:toggle-expand", this.chatStateManager.isDrawerExpanded);
    }
    static #_12 = (() => dt7948.n(this.prototype, "toggleExpand", [_object.action]))();
    close() {
      this.computeDrawerStyle();
      this.chatStateManager.didCloseDrawer();
      this.chat.activeChannel = null;
    }
    static #_13 = (() => dt7948.n(this.prototype, "close", [_object.action]))();
    didResize(element, _ref) {
      let {
        width,
        height
      } = _ref;
      this.chatDrawerSize.size = {
        width,
        height
      };
    }
    static #_14 = (() => dt7948.n(this.prototype, "didResize", [_object.action]))();
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatDrawer);
});